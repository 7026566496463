<template>
  <div class="main">
    <div class="logo">
      <img src="@/assets/images/logo.png" alt="" />
    </div>

    <div class="inputBox">
      <van-form ref="loginForm">
        <van-field
          required
          v-model="loginForm.phone"
          name="phone"
          type="tel"
          label="账号"
          placeholder="手机号"
          clearabl
          autocomplete="off"
          :rules="[
            { required: true, message: '请输入手机号' },
            { validator: validTel, message: '手机号格式错误' },
          ]"
        />
        <van-field
          required
          v-model="loginForm.password"
          type="password"
          name="password"
          label="密码"
          placeholder="密码"
          clearable
          autocomplete="off"
          :rules="[{ required: true, message: '请输入密码' }]"
        />
        <div style="margin: 30px 16px 0">
          <van-button
            :loading="submitLoading"
            class="submitBtn"
            block
            type="info"
            loading-text="登录中..."
            @click="handleSubmit"
            >登 录</van-button
          >
        </div>
      </van-form>
    </div>
  </div>
</template>
<script>
import { Form, Field, Notify } from "vant";
import { getToken } from "@/utils/auth";
export default {
  components: {
    vanForm: Form,
    vanField: Field,
  },
  data() {
    return {
      submitLoading: false,
      loginForm: {
        phone: "",
        password: "",
      },
    };
  },

  beforeRouteEnter(to, from, next) {
    let hasToken = getToken();
    if (hasToken) {
      next({ path: "/" });
    } else {
      next();
    }
  },

  created() {},

  methods: {
    // 校验手机号
    validTel(val) {
      return val === "" || /^1[3456789]\d{9}$/.test(val);
    },

    handleSubmit() {
      this.$refs.loginForm
        .validate()
        .then((res) => {
          this.submitLoading = true;
          this.loginForm.phone = this.loginForm.phone.trim();
          this.loginForm.password = this.loginForm.password.trim();
          this.$store
            .dispatch("user/userLogin", this.loginForm)
            .then(async () => {
              await this.$store.dispatch("user/getUserInfo");
              Notify({ type: "success", message: "登录成功！" });
              this.$router.push({ path: "/" });
              this.submitLoading = false;
            })
            .catch(() => {
              this.submitLoading = false;
              this.loginForm.phone = "";
              this.loginForm.password = "";
            });
        })
        .catch((err) => {});
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../../css.scss";
.main {
  width: 375px;
  height: 100%;
  overflow: hidden;
  background-color: $--bg-color;
  .logo {
    width: 150px;
    height: 150px;
    margin: 60px auto;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .inputBox {
    width: 345px;
    padding: 20px 0;
    margin: 50px auto;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    .submitBtn {
      background-color: $--theme-color;
      border-color: $--theme-color;
    }
  }
}
</style>
